import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { GatsbyImage } from "gatsby-plugin-image";

import ModalContact from "../Modal/ModalContact";
import nestLogo from "../../images/global/Nest Logo.svg";

const Footer = ({ hideFooter }) => {
  const getYear = () => {
    return new Date().getFullYear();
  };

  const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "global/Logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 192)
        }
      }
    }
  `);

  const navigation = {
    homeLoans: [
      {
        name: "Practice Areas",
        href: "/practice-areas/",
      },
      {
        name: "Our Team",
        href: "/our-team/",
      },
      {
        name: "Locations",
        href: "/locations/",
      },
      {
        name: "Reviews",
        href: "/testimonials/",
      },
      {
        name: "Blog",
        href: "/blog/",
      },
      {
        name: "Firm Videos",
        href: "/video-center/",
      },
    ],
  };

  return (
    <>
      <footer
        className={`bg-black pt-20 pb-12 lg:pb-6 text-center lg:text-left ${
          hideFooter && "hidden"
        }`}
      >
        <div className="container">
          <div className="grid lg:grid-cols-12 lg:justify-between space-y-16 lg:space-y-0 lg:space-x-8 mb-20 lg:mb-16">
            <div className="lg:col-start-1 lg:col-span-3">
              <div>
                <AniLink fade to="/">
                  <GatsbyImage
                    image={data.logo.childImageSharp.gatsbyImageData}
                    alt="JBP Legal Logo"
                    className="mb-10 lg:mb-16"
                  />
                </AniLink>

                <div className="flex items-center justify-center lg:justify-start space-x-6">
                  <a
                    href="https://www.facebook.com/jorgensenbrownellandpepin/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary-600 hover:text-primary-50"
                  >
                    <i className="fab fa-facebook-f text-xl"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/jorgensen-brownell-&-pepin-p-c-/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary-600 hover:text-primary-50"
                  >
                    <i className="fab fa-linkedin-in text-xl"></i>
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCg7Lreac4Y1jwjHpacx6ROg"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary-600 hover:text-primary-50"
                  >
                    <i className="fab fa-youtube text-xl"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="lg:col-end-13 lg:col-span-9">
              <div className="grid md:grid-cols-2 lg:flex justify-center lg:justify-between gap-y-12 lg:gap-y-0">
                <div>
                  <div className="text-gray-500 font-semibold mb-4 lg:mb-5">Our Firm</div>

                  <ul className="flex flex-col space-y-1.5">
                    {navigation.homeLoans.map((item) => (
                      <li key={item.name} className="whitespace-nowrap">
                        <AniLink
                          fade
                          to={item.href}
                          className="text-sm font-normal text-white/70 hover:text-white"
                        >
                          {item.name}
                        </AniLink>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="lg:ml-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-y-8 w-full lg:gap-y-6">
                  <div>
                    <AniLink
                      fade
                      to="/broomfield/"
                      className="text-gray-500 hover:text-secondary-50 font-semibold block mb-4 lg:mb-5"
                    >
                      Broomfield Office
                    </AniLink>

                    <address className="not-italic text-sm font-normal mb-4">
                      <a
                        href="https://goo.gl/maps/4xirs8pC3yyd7qNF7"
                        target="_blank"
                        rel="noreferrer"
                        className="text-gray-400/70 hover:text-secondary-50"
                      >
                        8001 Arista Pl
                        <br /> Suite 415
                        <br /> Broomfield, CO 80021
                      </a>
                    </address>

                    <a
                      href="tel:970-304-0075"
                      className="text-gray-400/70 hover:text-secondary-50 text-sm font-normal"
                    >
                      (970) 304-0075
                    </a>
                  </div>
                  <div>
                    <AniLink
                      fade
                      to="/denver/"
                      className="text-gray-500 hover:text-secondary-50 font-semibold block mb-4 lg:mb-5"
                    >
                      Denver Office
                    </AniLink>

                    <address className="not-italic text-sm font-normal mb-4">
                      <a
                        href="https://goo.gl/maps/HAYwjhGhMB5R1mkZ8"
                        target="_blank"
                        rel="noreferrer"
                        className="text-gray-400/70 hover:text-secondary-50"
                      >
                        3461 Ringsby Ct.
                        <br /> Suite 350
                        <br /> Denver, CO 80216
                      </a>
                    </address>

                    <a
                      href="tel:303-678-0560"
                      className="text-gray-400/70 hover:text-secondary-50 text-sm font-normal"
                    >
                      (303) 678-0560
                    </a>
                  </div>      
                  <div>
                    <AniLink
                      fade
                      to="/longmont/"
                      className="text-gray-500 hover:text-secondary-50 font-semibold block mb-4 lg:mb-5"
                    >
                      Longmont Office
                    </AniLink>

                    <address className="not-italic text-sm font-normal mb-4">
                      <a
                        href="https://goo.gl/maps/DxZwHWW6xLyDTrDD9"
                        target="_blank"
                        rel="noreferrer"
                        className="text-gray-400/70 hover:text-secondary-50"
                      >
                        900 S Main St
                        <br /> Suite 100
                        <br /> Longmont, CO 80501
                      </a>
                    </address>

                    <a
                      href="tel:720-491-3117"
                      className="text-gray-400/70 hover:text-secondary-50 text-sm font-normal"
                    >
                      (720) 491-3117
                    </a>
                  </div>
                  <div>
                    <AniLink
                      fade
                      to="/loveland/"
                      className="text-gray-500 hover:text-secondary-50 font-semibold block mb-4 lg:mb-5"
                    >
                      Loveland Office
                    </AniLink>

                    <address className="not-italic text-sm font-normal mb-4">
                      <a
                        href="https://goo.gl/maps/qsKJarM8tyr9KKMb9"
                        target="_blank"
                        rel="noreferrer"
                        className="text-gray-400/70 hover:text-secondary-50"
                      >
                        5285 McWhinney Blvd
                        <br /> Suite 100
                        <br /> Loveland, CO 80538
                      </a>
                    </address>

                    <a
                      href="tel:970-644-6275"
                      className="text-gray-400/70 hover:text-secondary-50 text-sm font-normal"
                    >
                      (970) 644-6275
                    </a>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap lg:flex-nowrap flex-col lg:flex-row justify-items-center lg:justify-between lg:items-center lg:pt-4 mb-8">
            <div className="lg:flex lg:items-center">
              <ul className="flex items-center flex-wrap justify-center lg:space-x-5 lg:justify-start">
                <div className="w-full md:w-auto flex flex-wrap justify-center lg:space-x-5">
                  <li className="text-sm text-gray-300/70">© {getYear()} JBP Legal</li>

                  <div className="text-gray-300/70 mx-1 lg:hidden">|</div>

                  <li className="text-sm">
                    <AniLink
                      fade
                      to="/privacy-policy/"
                      className="text-gray-300/70 hover:text-gray-300 no-underline"
                    >
                      Privacy Policy
                    </AniLink>
                  </li>
                </div>

                {/* <div className="w-full md:w-auto flex flex-wrap justify-center lg:space-x-5 mt-1 md:mt-0">
                  <li className="text-sm">
                    <AniLink
                      fade
                      to="/site-map/"
                      className="text-gray-300/70 hover:text-gray-300 no-underline"
                    >
                      Sitemap
                    </AniLink>
                  </li>

                  <div className="text-gray-300/70 mx-1 lg:hidden">|</div>

                  <li className="text-sm">
                    <AniLink
                      fade
                      to="#"
                      className="text-gray-300/70 hover:text-gray-300 no-underline"
                    >
                      Disclaimer
                    </AniLink>
                  </li>
                </div> */}
              </ul>
            </div>

            <div className="text-sm w-full lg:w-auto mt-5 lg:mt-0">
              <a
                className="group no-underline text-sm text-gray-300/70 hover:text-secondary-50 flex items-center justify-center lg:justify-start space-x-1"
                href="https://www.wisedigitalpartners.com/nest-by-wise/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Powered by</span>{" "}
                <img
                  src={nestLogo}
                  alt="NEST logo"
                  className="opacity-70 group-hover:opacity-100 transition-all duration-300 ease-linear"
                />
              </a>
            </div>
          </div>

          <p className="text-sm mb-0 text-white/70">
            The information on this website is for general information purposes only. Nothing on
            this site should be taken as legal advice for any individual case or situation. This
            information is not intended to create, and receipt or viewing does not constitute, an
            attorney-client relationship.
          </p>
        </div>
      </footer>

      <ModalContact />
    </>
  );
};

export default Footer;
